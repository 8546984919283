import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '@/env';
import { notify } from '@sobrus-com/sobrus-design-system-v2';
import i18n from '@/i18n/i18next';

let isDisconnected = false;
const MAX_RETRY_ATTEMPTS = 3; // Max number of retries

const AXIOS = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  withCredentials: true,
  headers: {
    Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
  },
});

AXIOS.interceptors.request.use(async (config: any) => {
  const token = Cookies.get('csrfToken');
  if (token) {
    if (config.method !== 'get') config.headers['X-CSRF-Token'] = token;
  }
  return config;
});

AXIOS.interceptors.response.use(
  (response) => {
    isDisconnected = false;
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    const retryCount = originalRequest._retryCount || 0; // Track retry count

    if (
      (!error.response || error?.code === 'ERR_NETWORK' || (error instanceof AxiosError && error.code === AxiosError.ERR_NETWORK)) &&
      !isDisconnected
    ) {
      return;
    } else if (
      error?.response?.status === 401 &&
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/login/password' &&
      window.location.pathname !== '/auth/password-recovery' &&
      window.location.pathname !== '/auth/password-recovery/success' &&
      window.location.pathname !== '/auth/password-reset' &&
      window.location.pathname !== '/auth/password-reset/mobile' &&
      window.location.pathname !== '/signup' &&
      window.location.pathname !== '/authorize'
    ) {
      localStorage.clear();
      window.location.href = '/login';
    } else if (error?.message === 'canceled' || error.code === 'ERR_CANCELED') {
      return;
    } else if (error?.response?.status === 404) {
      throw error;
    } else if (error?.response?.status === 403 && retryCount < MAX_RETRY_ATTEMPTS) {
      console.log('CSRF token expired. Attempting to refresh...');

      // Increment retry count
      originalRequest._retryCount = retryCount + 1;

      // Get new CSRF token and retry request
      const newToken = error?.response?.data?.errors?.csrfToken;
      Cookies.set('csrfToken', newToken);
      originalRequest.headers['X-CSRF-Token'] = newToken;

      return AXIOS(originalRequest); // Retry the original request
    } else if (retryCount >= MAX_RETRY_ATTEMPTS) {
      notify({
        type: 'danger',
        msg: i18n.t('Max retry attempts reached. Please try again.'),
        delay: 5000,
      });
      throw error;
    } else if (!isDisconnected) {
      let message = '';
      if (error?.response?.data?.errorMessage) {
        message = error?.response?.data?.errorMessage;
      }
      throw message;
    } else {
      throw error;
    }

    throw error;
  },
);

export default AXIOS;
