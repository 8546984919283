import { Component, ErrorInfo, LazyExoticComponent, ReactNode } from 'react';
import Error from '@/pages/ErrorBoundary';
import * as Sentry from '@sentry/react';
import { ErrorModal } from '@/pages/ErrorBoundary/ErrorBoundary';
interface ErrorBoundaryProps {
  children: ReactNode;
  notDisplay?: boolean;
  type?: 'modal' | 'page';
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: string;
  _errorInfo: string;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: '', _errorInfo: '' };
  }

  static getDerivedStateFromError(error: Error) {
    console.error('getDerivedStateFromError', error);

    return { hasError: true };
  }

  componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
    console.error({ _errorInfo, error });
    this.setState({
      error: JSON.stringify(error),
      _errorInfo: JSON.stringify(_errorInfo),
    });
    Sentry.captureException(error);
  }

  render() {
    const { hasError } = this.state;
    const { children, type } = this.props;

    if (hasError) {
      if (type === 'modal') {
        return <ErrorModal />;
      }
      return <Error />;
    }

    return children;
  }
}
export const ErrorBoundaryPages = (CurrentComponent: LazyExoticComponent<() => JSX.Element>) => {
  class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
      super(props);
      this.state = { hasError: false, error: '', _errorInfo: '' };
    }

    static getDerivedStateFromError(error: Error) {
      console.error('getDerivedStateFromError', error);

      return { hasError: true };
    }

    componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
      console.error({ _errorInfo, error });
      this.setState({
        error: JSON.stringify(error),
        _errorInfo: JSON.stringify(_errorInfo),
      });
      Sentry.captureException(error);
    }

    render() {
      const { hasError } = this.state;

      if (hasError) {
        return <Error />;
      }

      return <CurrentComponent />;
    }
  }
  return ErrorBoundary;
};
