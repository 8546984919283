import errorBoundry from '@/assets/errorBoundry.svg';
import './ErrorBoundary.scss';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from '@sobrus-com/sobrus-design-system-v2';

const ErrorBoundary = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className='errorboundary'>
        <div className='errorboundary__imagecontainer'>
          <img alt='' src={errorBoundry} />
        </div>
        <p className='title'>{t('Oops, something went wrong!')}</p>
        <p className='sub__title'>
          {t(
            "We're sorry, but an error has occurred. Our team has been notified and we&apos;re working to resolve the issue as quickly as possible.",
          )}
        </p>
      </div>
    </>
  );
};

export default ErrorBoundary;

export const ErrorModal = () => {
  const { t } = useTranslation();
  return (
    <Modal setOpen={() => window.location.reload()}>
      <ModalHeader setOpen={() => window.location.reload()} />
      <ModalBody>
        <div className='errorboundary-modal'>
          <div className='errorboundary'>
            <div className='errorboundary__imagecontainer'>
              <img alt='' src={errorBoundry} />
            </div>
            <p className='title'>{t('Oops, something went wrong!')}</p>
            <p className='sub__title'>
              {t(
                "We're sorry, but an error has occurred. Our team has been notified and we&apos;re working to resolve the issue as quickly as possible.",
              )}
            </p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
