export const calculateTint = (color: string, percent: number) => {
  const hexToRgb = (hex: string) =>
    hex
      ?.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (_m, r, g, b) => '#' + r + r + g + g + b + b)
      ?.substring(1)
      ?.match(/.{2}/g)
      ?.map((x) => parseInt(x, 16));

  const rgbColor = hexToRgb(color);
  const tintedRgb = rgbColor?.map((channel) => Math.round(channel + (255 - channel) * (percent / 100)));

  return `rgb(${tintedRgb?.join(', ')})`;
};
