import { notify } from '@sobrus-com/sobrus-design-system-v2';
import { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { useRegisterSW } from 'virtual:pwa-register/react';

export const useNotify = () => {
  const navigationType = useNavigationType();
  const location = useLocation();
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered() {
      // console.info('onRegistered,r', r);
    },
  });
  useEffect(() => {
    if (needRefresh) {
      updateServiceWorker(true);
    }
    if (navigationType === 'PUSH')
      if (location?.state?.detail === 'success') {
        notify({
          type: location?.state?.detail,
          msg: location?.state?.message,
          position: location?.state?.position,
          delay: 5000,
        });
      }
  }, [location?.pathname, navigationType]);
};
