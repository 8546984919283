import { ConnectedAppTypes, connectedAppInfos } from '@/data/applications';
import logo from '@/assets/applicationsLogo/account.svg';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useLayoutEffect, useState } from 'react';

export const useGetConnectedAppInfo = (title?: string) => {
  const [params] = useSearchParams();
  const [connectedApp, setConnectedApp] = useState<ConnectedAppTypes>({
    name: 'Sobrus Account',
    logo,
  });

  const setAppConfig = useCallback(
    (current: ConnectedAppTypes) => {
      if (current && current?.palette) {
        const root = document.querySelector(':root') as HTMLElement;
        root.style.setProperty('--sob-v2-color-palette-primary', current?.palette?.primary);
        root.style.setProperty('--sob-v2-color-palette-primary-hover', current?.palette?.primary_hover);
        root.style.setProperty('--sob-v2-color-palette-secondary', current?.palette?.secondary);
        root.style.setProperty('--sob-v2-color-palette-secondary-hover', current?.palette?.secondary_hover);
      }
      if (document.title && current && current?.name) {
        document.title = `${current?.name} ${title ? ' - ' + title : ''}`;
      }
      if (connectedApp) {
        const favIcon = document.querySelector('link[rel="icon"]');
        if (favIcon) favIcon.setAttribute('href', `${connectedApp?.favIcon || 'favicon.ico'}`);
      }
    },
    [connectedApp],
  );
  const getConnectedApp = useCallback(() => {
    try {
      const redirectUri = params.get('redirectUri');
      if (redirectUri) {
        const params = new URL(redirectUri)?.searchParams;
        const client_id = params?.get('client_id');
        if (client_id && connectedAppInfos[client_id]) {
          setConnectedApp(connectedAppInfos[client_id]);
          setAppConfig(connectedAppInfos[client_id]);
        }
      }
    } catch (e) {
      // setConnectedApp({
      //   name: 'Sobrus Account',
      //   logo,
      // });
    }
  }, [params, setAppConfig]);

  useLayoutEffect(() => {
    getConnectedApp();
  }, [getConnectedApp]);
  return { connectedApp, setAppConfig };
};
