import { ErrorBoundaryPages } from '@/services/ErrorBoundry';
import { lazy } from 'react';

const Home = lazy(() => import('@/pages/Home/Home'));
const ProfileEdit = lazy(() => import('@/pages/ProfileEdit/ProfileEdit'));
const LoginHistory = lazy(() => import('@/pages/LoginHistory/LoginHistory'));
const Password = lazy(() => import('@/pages/Password/Password'));

const Login = lazy(() => import('@/pages/Login/Login'));
const LoginPassword = lazy(() => import('@/pages/LoginPassword/LoginPassword'));

const SignUp = lazy(() => import('@/pages/SignUp/SignUp'));
const PasswordRecovery = lazy(() => import('@/pages/PasswordRecovery/PasswordRecovery'));
const PasswordRecoverySuccess = lazy(() => import('@/pages/PasswordRecovery/PasswordRecoverySuccess/PasswordRecoverySuccess'));
const PasswordReset = lazy(() => import('@/pages/PasswordRecovery/PasswordReset/PasswordReset'));
const PasswordResetMobile = lazy(() => import('@/pages/PasswordRecovery/PasswordResetMobile/PasswordResetMobile'));
const Autorize = lazy(() => import('@/pages/Autorize/Autorize'));

const Mobilehome = lazy(() => import('@/Mobile/Mobilehome/Mobilehome'));
const EditProfile = lazy(() => import('@/Mobile/EditProfile/EditProfile'));
const History = lazy(() => import('@/Mobile/History/History'));

export const APP_PROTECTED_ROUTE = [
  { component: ErrorBoundaryPages(Home), mobileComponent: ErrorBoundaryPages(Mobilehome), path: '/' },
  { component: ErrorBoundaryPages(Home), mobileComponent: ErrorBoundaryPages(Mobilehome), path: '/home' },
  { component: ErrorBoundaryPages(ProfileEdit), mobileComponent: ErrorBoundaryPages(EditProfile), path: '/profile_edit' },
  { component: ErrorBoundaryPages(LoginHistory), mobileComponent: ErrorBoundaryPages(History), path: '/login_history' },
  { component: ErrorBoundaryPages(Password), mobileComponent: ErrorBoundaryPages(Password), path: '/password' },
];

export const APP_ROUTE = [
  { component: ErrorBoundaryPages(Login), path: '/login' },
  { component: ErrorBoundaryPages(LoginPassword), path: '/login/password' },
  { component: ErrorBoundaryPages(SignUp), path: '/signup' },
  { component: ErrorBoundaryPages(PasswordRecovery), path: '/auth/password-recovery' },
  { component: ErrorBoundaryPages(PasswordRecoverySuccess), path: '/auth/password-recovery/success' },
  { component: ErrorBoundaryPages(PasswordReset), path: '/auth/password-reset' },
  { component: ErrorBoundaryPages(PasswordResetMobile), path: '/auth/password-reset/mobile' },
  { component: ErrorBoundaryPages(Autorize), path: '/authorize' },
];
