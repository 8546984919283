import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@sobrus-com/sobrus-design-system-v2/dist/style.css';
import App from './App';
import './index.scss';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import './i18n/i18next';
import { BrowserRouter } from 'react-router-dom';
import { store } from './redux/store';
import { registerSW } from 'virtual:pwa-register';
import ReactDOM from 'react-dom/client';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

Sentry.init({
  release: 'sobrus-account@' + '2.0.1',
  environment: import.meta.env.VITE_REACT_APP_ENV,
  dsn: 'https://3faea271c0e8a5750b433ab40d0cf59d@o4505092585816064.ingest.us.sentry.io/4507867215364096',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  enabled: import.meta.env.VITE_REACT_APP_ENV !== 'development',
  autoSessionTracking: true,
  tracePropagationTargets: ['localhost', /^https:\/\/api\.account\.sbrus\.${EXT}/],
  beforeSend(event) {
    if (event.request?.url?.includes('/api/')) {
      return null;
    }
    if (event.level === 'warning') {
      return null; // Ne pas envoyer les avertissements
    }
    return event;
  },
  tracesSampleRate: 0.2,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    /^Failed to fetch dynamically imported module/,
    /^Unable to preload CSS/,
    /^Request aborted/,
    /^Network Error/,
    /^chrome-extension:/,
    /^error loading dynamically imported/,
    /^Importing a module script failed/,
  ],
});

const intervalMS = 15 * 60 * 1000;

const _updateSW = registerSW({
  onRegisteredSW(swUrl: string, registration: ServiceWorkerRegistration | undefined) {
    if (!registration) return;

    setInterval(async () => {
      try {
        if (!registration.installing && navigator) {
          if ('connection' in navigator && !navigator.onLine) return;
          if (!navigator.serviceWorker.controller) return;

          const response = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              'cache-control': 'no-cache',
            },
          });

          if (response.status === 200) {
            await registration.update();
          } else {
            console.warn('Service worker update fetch failed:', response);
          }
        }
      } catch (error) {
        console.error('Error during service worker update check:', error);
      }
    }, intervalMS);
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);
