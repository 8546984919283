import { useGetConnectedAppInfo } from '@/hooks/useGetConnectedAppInfo';
import { SpinnerLoading } from '@sobrus-com/sobrus-design-system-v2';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

interface LoadingPageProps {
  height?: string;
  style?: CSSProperties;
}
export function LoadingPage({ height, style }: LoadingPageProps) {
  const { t } = useTranslation();
  const { connectedApp } = useGetConnectedAppInfo();
  return (
    <div
      style={{
        height: height || '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
    >
      <SpinnerLoading loading color={connectedApp?.palette?.primary} title={t('Loading in progress')} />
    </div>
  );
}
