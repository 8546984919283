import { LoadingPage } from '@/components/Loaders/LoadingPage';
import { useAppSelector } from '@/hooks/reduxHooks';
import { Navigate } from 'react-router-dom';

interface ProtectedRoutes {
  element: any;
}
const ProtectedRoutes = ({ element: Component }: ProtectedRoutes) => {
  const { user, loading } = useAppSelector((state) => state.auth);
  if (loading) return <LoadingPage />;
  else if (user && Object.values(user)?.length > 0) return <Component />;
  else return <Navigate to={'/login'} />;
};

export default ProtectedRoutes;
