import './App.scss';
import { Toast } from '@sobrus-com/sobrus-design-system-v2';
import { Route, Routes } from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import { LoadingPage } from './components/Loaders/LoadingPage';

import { useNotify } from './hooks/useNotify';
import { APP_PROTECTED_ROUTE, APP_ROUTE } from './data/AppRoutes';
import ProtectedRoutes from './services/ProtectedRoutes';
import useAuth from './hooks/useAuth';
export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

function App() {
  useNotify();
  const { getUser } = useAuth();
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    getUser(signal);
    return () => controller.abort();
  }, [getUser]);

  return (
    <>
      <Toast />
      <Routes>
        {APP_ROUTE.map(({ component: Component, path }) => (
          <Route
            key={path}
            element={
              <Suspense fallback={<LoadingPage />}>
                <Component>
                  <></>
                </Component>
              </Suspense>
            }
            path={path}
          />
        ))}

        {APP_PROTECTED_ROUTE.map(({ component: Component, mobileComponent: MobileComponent, path }) => (
          <Route
            key={path}
            element={
              <Suspense fallback={<LoadingPage />}>
                <ProtectedRoutes element={!isMobile ? Component : MobileComponent} />
              </Suspense>
            }
            path={path}
          />
        ))}
      </Routes>
    </>
  );
}

export default App;
