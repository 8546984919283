import { useState } from 'react';

import API from '@/services/API';

interface UseAddUpdateState<T> {
  url: string;
  formData: unknown;
  cb?: (data?: T) => void;
  cbError?: (error?: any) => void;
  catchCallback?: (error: any) => void;
  params?: { [key: string]: string | undefined };
}

export const useAddUpdate = () => {
  const [formLoading, setFormLoading] = useState(false);
  async function Add<T>({ url, formData, cb, params, cbError }: UseAddUpdateState<T>) {
    try {
      setFormLoading(true);
      const res = await API.post(`${url}`, formData, { params });
      cb?.(res.data as T);
      setFormLoading(false);
    } catch (error) {
      cbError?.(error);
      setFormLoading(false);
    }
  }
  async function Update<T>({ url, formData, cb, params, cbError }: UseAddUpdateState<T>) {
    try {
      setFormLoading(true);
      const res = await API.patch(`${url}`, formData, { params });
      cb?.((res?.data as T) || ({} as T));
      setFormLoading(false);
    } catch (error) {
      cbError?.(error);
      setFormLoading(false);
    }
  }
  return { Add, formLoading, Update };
};
