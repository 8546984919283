import pharmaLogo from '@/assets/applicationsLogo/pharma.png';
import pharmaLogoBg from '@/assets/applicationsLogo/pharma-bg.png';
import accountLogo from '@/assets/applicationsLogo/account.svg';
import Logo_Sobrus_macompta from '@/assets/applicationsLogo/Logo_Sobrus_macompta.png';
import sobrus_central from '@/assets/applicationsLogo/sobrus_central.png';
import logo_admin from '@/assets/applicationsLogo/logo_admin.png';
import logo_fidny from '@/assets/applicationsLogo/logo_fidny.png';
import Logo_Sobrus_SNS from '@/assets/applicationsLogo/Logo_Sobrus_SNS.png';
import Logo_Sobrus_workspace from '@/assets/applicationsLogo/Logo_Sobrus_workspace.png';
import Logo_Sobrus_Labs from '@/assets/applicationsLogo/Logo_Sobrus_Labs.png';
import sobrus_med from '@/assets/applicationsLogo/sobrus_med.png';
import logo_monsite from '@/assets/applicationsLogo/logo_monsite.png';
import chezLeDocteur from '@/assets/applicationsLogo/chezLeDocteur.png';
import MonPharmacien from '@/assets/applicationsLogo/MonPharmacien.svg';
import paraprice from '@/assets/ecopara/paraprice.png';
import evaplanetbeauty from '@/assets/ecopara/evaplanetbeauty.jpeg';
import alyssepara from '@/assets/ecopara/alyssepara.jpeg';
import paralouiz from '@/assets/ecopara/paralouiz.png';
import pararivage from '@/assets/ecopara/pararivage.png';
import parasquare from '@/assets/ecopara/parasquare.png';
import notreparamall from '@/assets/ecopara/notreparamall.png';
import param2a from '@/assets/ecopara/param2a.png';
import balticbeautyshop from '@/assets/ecopara/balticbeautyshop.png';
import salus from '@/assets/ecopara/salus.png';
import paradiem from '@/assets/ecopara/paradiem.png';
import alopara from '@/assets/ecopara/alopara.png';
import pharmatrex from '@/assets/ecopara/pharmatrex.png';
import wellness from '@/assets/ecopara/wellness.jpg';
import paraljazira from '@/assets/ecopara/paraljazira.png';
import santenature from '@/assets/ecopara/santenature.png';
import naturalpara from '@/assets/ecopara/naturalpara.png';
import Ecopara_logo from '@/assets/applicationsLogo/Ecopara_logo.svg';
import Bestpara from '@/assets/ecopara/Bestpara.png';

import { calculateTint } from '@/helpers/calculeColoe';

export type ConnectedAppTypes = { [key: string]: any };

export const connectedAppInfos: ConnectedAppTypes =
  import.meta.env.VITE_REACT_APP_ENV !== 'production'
    ? {
        // Pharma
        10000: {
          name: 'Sobrus Pharma',
          logo: pharmaLogo,
          palette: {
            primary: '#25b8aa',
            primary_hover: '#198880',
            secondary: '#d0f7f0',
            secondary_hover: '#a1eee0',
          },
          loginBackgroundImg: pharmaLogoBg,
          favIcon: 'pharma_favicon.ico',
        },
        10002: {
          name: 'Sobrus Pharma',
          logo: accountLogo,
          favIcon: 'favicon.ico',
          notSignUp: true,
        },
        // mobile ios
        10001: {
          name: 'Sobrus Pharma',
          logo: accountLogo,
          favIcon: 'favicon.ico',
          notSignUp: true,
        },
        10037: {
          name: 'Sobrus Macompta',
          logo: Logo_Sobrus_macompta,
          palette: {
            primary: '#25b8aa',
            primary_hover: '#198880',
            secondary: '#d0f7f0',
            secondary_hover: '#a1eee0',
          },
          loginBackgroundImg: pharmaLogoBg,
          favIcon: 'pharma_favicon.ico',
        },
        // Sobrus Central
        10007: {
          name: 'Sobrus Central',
          logo: sobrus_central,
          palette: {
            primary: '#16D535',
            primary_hover: calculateTint('#16D535', -30),
            secondary: calculateTint('#16D535', 90),
            secondary_hover: calculateTint('#16D535', 70),
          },
          favIcon: 'favicon.ico',
        },
        // Sobrus Admin
        10060: {
          name: 'Sobrus Admin',
          logo: logo_admin,
          favIcon: 'favicon.ico',
        },
        // Sobrus FIDNY
        10061: {
          name: 'Sobrus FIDNY',
          logo: logo_fidny,
          palette: {
            primary: '#1bb9ac',
            primary_hover: calculateTint('#1bb9ac', -30),
            secondary: calculateTint('#1bb9ac', 90),
            secondary_hover: calculateTint('#1bb9ac', 70),
          },
          favIcon: 'fidny_favicon.ico',
        },
        10059: {
          name: 'Sobrus SNS',
          logo: Logo_Sobrus_SNS,
          favIcon: 'favicon.ico',
        },
        10013: {
          name: 'Sobrus Workspace',
          logo: Logo_Sobrus_workspace,
          palette: {
            primary: '#9D73D3',
            primary_hover: '#834DB4',
            secondary: '#F2EDFA',
            secondary_hover: '#E5DEF6',
          },
          favIcon: 'workspace_favicon.ico',
        },
        10068: {
          name: 'Sobrus Workspace',
          logo: Logo_Sobrus_workspace,
          palette: {
            primary: '#9D73D3',
            primary_hover: '#834DB4',
            secondary: '#F2EDFA',
            secondary_hover: '#E5DEF6',
          },
          favIcon: 'workspace_favicon.ico',
          notSignUp: true,
        },
        10067: {
          name: 'Sobrus Workspace',
          logo: Logo_Sobrus_workspace,
          palette: {
            primary: '#9D73D3',
            primary_hover: '#834DB4',
            secondary: '#F2EDFA',
            secondary_hover: '#E5DEF6',
          },
          favIcon: 'workspace_favicon.ico',
          notSignUp: true,
        },
        10065: {
          name: 'Sobrus Labs',
          logo: Logo_Sobrus_Labs,
          palette: {
            primary: '#CC3244',
            primary_hover: '#AC2437',
            secondary: '#FCE8E7',
            secondary_hover: '#F9D2D3',
          },
          favIcon: 'lab_favicon.ico',
        },
        10006: {
          name: 'Sobrus MED',
          logo: sobrus_med,
          favIcon: 'favicon.ico',
        },
        10011: {
          name: 'Sobrus MED',
          logo: sobrus_med,
          favIcon: 'favicon.ico',
        },
        10012: {
          name: 'Sobrus MED',
          logo: sobrus_med,
          favIcon: 'favicon.ico',
        },
        10008: {
          name: 'MonPharmacien',
          logo: MonPharmacien,
          palette: {
            primary: '#3BB888',
            primary_hover: calculateTint('#3BB888', -30),
            secondary: calculateTint('#3BB888', 90),
            secondary_hover: calculateTint('#3BB888', 70),
          },
          favIcon: 'monpharmacien_favicon.ico',
        },
        100051: {
          name: 'MonPharmacien',
          logo: MonPharmacien,
          palette: {
            primary: '#3BB888',
            primary_hover: calculateTint('#3BB888', -30),
            secondary: calculateTint('#3BB888', 90),
            secondary_hover: calculateTint('#3BB888', 70),
          },
          favIcon: 'monpharmacien_favicon.ico',
        },
        10056: {
          name: 'MonSite',
          logo: logo_monsite,
          palette: {
            primary: '#00B1D8',
            primary_hover: calculateTint('#00B1D8', -30),
            secondary: calculateTint('#00B1D8', 90),
            secondary_hover: calculateTint('#00B1D8', 70),
          },
          favIcon: 'favicon.ico',
        },
        10050: {
          name: 'ChezLeDocteur',
          logo: chezLeDocteur,
          palette: {
            primary: '#00B1D8',
            primary_hover: calculateTint('#00B1D8', -30),
            secondary: calculateTint('#00B1D8', 90),
            secondary_hover: calculateTint('#00B1D8', 70),
          },
          favIcon: 'chezledocteur_favicon.ico',
        },
        10014: {
          name: 'Ecopara',
          logo: Ecopara_logo,
          palette: {
            primary: '#3F8C7D',
            primary_hover: '#317066',
            secondary: '#D8EFE8',
            secondary_hover: '#B2DDD1',
          },
          favIcon: 'ecopara_favicon.ico',
          notSignUp: true,
        },
        // Ecopara com global
        10055: {
          name: 'Ecopara',
          logo: Ecopara_logo,
          palette: {
            primary: '#3F8C7D',
            primary_hover: '#317066',
            secondary: '#D8EFE8',
            secondary_hover: '#B2DDD1',
          },
          favIcon: 'ecopara_favicon.ico',
          notSignUp: true,
        },
        10064: {
          name: 'Customdomain',
          logo: notreparamall,
          palette: {
            primary: '#5AB9A4',
            primary_hover: calculateTint('#5AB9A4', -30),
            secondary: calculateTint('#5AB9A4', 90),
            secondary_hover: calculateTint('#5AB9A4', 70),
          },

          customDomain: true,
          favIcon: 'ecopara_favicon.ico',
        },
      }
    : {
        // Pharma
        10000: {
          name: 'Sobrus Pharma',
          logo: pharmaLogo,
          palette: {
            primary: '#25b8aa',
            primary_hover: '#198880',
            secondary: '#d0f7f0',
            secondary_hover: '#a1eee0',
          },
          loginBackgroundImg: pharmaLogoBg,
          favIcon: 'pharma_favicon.ico',
        },
        10002: {
          name: 'Sobrus Pharma',
          logo: accountLogo,
          favIcon: 'favicon.ico',
          notSignUp: true,
        },
        // mobile ios
        10001: {
          name: 'Sobrus Pharma',
          logo: accountLogo,
          favIcon: 'favicon.ico',
          notSignUp: true,
        },
        // Sobrus Macompta
        10037: {
          name: 'Sobrus Macompta',
          logo: Logo_Sobrus_macompta,
          palette: {
            primary: '#25b8aa',
            primary_hover: '#198880',
            secondary: '#d0f7f0',
            secondary_hover: '#a1eee0',
          },
          loginBackgroundImg: pharmaLogoBg,
          favIcon: 'pharma_favicon.ico',
        },
        // Sobrus Central
        10007: {
          name: 'Sobrus Central',
          logo: sobrus_central,
          palette: {
            primary: '#16D535',
            primary_hover: calculateTint('#16D535', -30),
            secondary: calculateTint('#16D535', 90),
            secondary_hover: calculateTint('#16D535', 70),
          },
          // loginBackgroundImg: require('../../img/central_bg.png'),
          favIcon: 'favicon.ico',
        },
        // Sobrus Admin
        10060: {
          name: 'Sobrus Admin',
          logo: logo_admin,
          favIcon: 'favicon.ico',
        },
        // Sobrus FIDNY
        10061: {
          name: 'Sobrus FIDNY',
          logo: logo_fidny,
          palette: {
            primary: '#1bb9ac',
            primary_hover: calculateTint('#1bb9ac', -30),
            secondary: calculateTint('#1bb9ac', 90),
            secondary_hover: calculateTint('#1bb9ac', 70),
          },
          favIcon: 'fidny_favicon.ico',
        },
        // Sobrus SNS
        10059: {
          name: 'Sobrus SNS',
          logo: Logo_Sobrus_SNS,
          favIcon: 'favicon.ico',
        },
        // sns android
        10082: {
          name: 'Sobrus SNS',
          logo: Logo_Sobrus_SNS,
          favIcon: 'favicon.ico',
          notSignUp: true,
        },
        // sns ios
        10081: {
          name: 'Sobrus SNS',
          logo: Logo_Sobrus_SNS,
          notSignUp: true,
        },
        // Sobrus Workspace
        10013: {
          name: 'Sobrus Workspace',
          logo: Logo_Sobrus_workspace,
          palette: {
            primary: '#9D73D3',
            primary_hover: '#834DB4',
            secondary: '#F2EDFA',
            secondary_hover: '#E5DEF6',
          },
          favIcon: 'workspace_favicon.ico',
        },
        // mobile  android
        10070: {
          name: 'Sobrus Workspace',
          logo: Logo_Sobrus_workspace,
          palette: {
            primary: '#9D73D3',
            primary_hover: '#834DB4',
            secondary: '#F2EDFA',
            secondary_hover: '#E5DEF6',
          },
          favIcon: 'workspace_favicon.ico',
          notSignUp: true,
        },
        // mobile ios
        10069: {
          name: 'Sobrus Workspace',
          logo: Logo_Sobrus_workspace,
          palette: {
            primary: '#9D73D3',
            primary_hover: '#834DB4',
            secondary: '#F2EDFA',
            secondary_hover: '#E5DEF6',
          },
          favIcon: 'workspace_favicon.ico',
          notSignUp: true,
        },
        // Sobrus Labs
        10003: {
          name: 'Sobrus Labs',
          logo: Logo_Sobrus_Labs,
          palette: {
            primary: '#CC3244',
            primary_hover: '#AC2437',
            secondary: '#FCE8E7',
            secondary_hover: '#F9D2D3',
          },
          favIcon: 'lab_favicon.ico',
        },
        // Labs mobile  android
        10091: {
          name: 'Sobrus Labs',
          logo: Logo_Sobrus_Labs,
          palette: {
            primary: '#CC3244',
            primary_hover: '#AC2437',
            secondary: '#FCE8E7',
            secondary_hover: '#F9D2D3',
          },
          favIcon: 'lab_favicon.ico',
          notSignUp: true,
        },
        // Labs mobile ios
        10092: {
          name: 'Sobrus Labs',
          logo: Logo_Sobrus_Labs,
          palette: {
            primary: '#CC3244',
            primary_hover: '#AC2437',
            secondary: '#FCE8E7',
            secondary_hover: '#F9D2D3',
          },
          favIcon: 'lab_favicon.ico',
          notSignUp: true,
        },
        // Sobrus MED
        10006: {
          name: 'Sobrus MED',
          logo: sobrus_med,
          favIcon: 'favicon.ico',
        },
        10012: {
          name: 'Sobrus MED',
          logo: sobrus_med,
          favIcon: 'favicon.ico',
        },
        10011: {
          name: 'Sobrus MED',
          logo: sobrus_med,
          favIcon: 'favicon.ico',
        },
        10008: {
          name: 'MonPharmacien',
          logo: MonPharmacien,
          palette: {
            primary: '#3BB888',
            primary_hover: calculateTint('#3BB888', -30),
            secondary: calculateTint('#3BB888', 90),
            secondary_hover: calculateTint('#3BB888', 70),
          },
          favIcon: 'monpharmacien_favicon.ico',
        },
        10051: {
          name: 'MonPharmacien',
          logo: MonPharmacien,
          palette: {
            primary: '#3BB888',
            primary_hover: calculateTint('#3BB888', -30),
            secondary: calculateTint('#3BB888', 90),
            secondary_hover: calculateTint('#3BB888', 70),
          },
          favIcon: 'monpharmacien_favicon.ico',
        },
        10056: {
          name: 'MonSite',
          logo: logo_monsite,
          palette: {
            primary: '#00B1D8',
            primary_hover: calculateTint('#00B1D8', -30),
            secondary: calculateTint('#00B1D8', 90),
            secondary_hover: calculateTint('#00B1D8', 70),
          },
          favIcon: 'favicon.ico',
        },
        10050: {
          name: 'ChezLeDocteur',
          logo: chezLeDocteur,
          palette: {
            primary: '#00B1D8',
            primary_hover: calculateTint('#00B1D8', -30),
            secondary: calculateTint('#00B1D8', 90),
            secondary_hover: calculateTint('#00B1D8', 70),
          },
          favIcon: 'chezledocteur_favicon.ico',
        },
        // Ecopara

        // paraprice
        10063: {
          name: 'Paraprice',
          logo: paraprice,
          logoSizes: {
            width: 200,
            height: 57,
          },
          palette: {
            primary: '#30BFB1',
            primary_hover: calculateTint('#30BFB1', -30),
            secondary: calculateTint('#30BFB1', 90),
            secondary_hover: calculateTint('#30BFB1', 70),
          },
          favIcon: '/src/assets/ecopara/paraprice.png',
        },
        10079: {
          name: 'Evaplanet beauty',
          logo: evaplanetbeauty,
          logoSizes: {
            width: 200,
            height: 57,
          },
          palette: {
            primary: '#881168',
            primary_hover: calculateTint('#881168', -30),
            secondary: calculateTint('#881168', 90),
            secondary_hover: calculateTint('#881168', 70),
          },
          customDomain: true,
          favIcon: '/src/assets/ecopara/evaplanetbeauty.png',
        },
        //   https://alyssepara.com/
        10083: {
          name: 'Alyssepara',
          logo: alyssepara,
          logoSizes: {
            width: 200,
            height: 200,
          },
          palette: {
            primary: '#31afb4',
            primary_hover: calculateTint('#31afb4', -30),
            secondary: calculateTint('#31afb4', 90),
            secondary_hover: calculateTint('#31afb4', 70),
          },
          customDomain: true,
          favIcon: '/src/assets/ecopara/alyssepara.png',
        },
        10085: {
          name: 'Paralouiz',
          logo: paralouiz,
          logoSizes: {
            width: 200,
            height: 200,
          },
          palette: {
            primary: '#9ec22a',
            primary_hover: calculateTint('#9ec22a', -30),
            secondary: calculateTint('#9ec22a', 90),
            secondary_hover: calculateTint('#9ec22a', 70),
          },
          customDomain: true,
          favIcon: '/src/assets/ecopara/paralouiz.png',
        },
        10098: {
          name: 'Bestpara',
          logo: Bestpara,
          logoSizes: {
            width: 200,
            height: 66,
          },
          palette: {
            primary: '#64b9a4',
            primary_hover: calculateTint('#64b9a4', -30),
            secondary: calculateTint('#64b9a4', 90),
            secondary_hover: calculateTint('#64b9a4', 70),
          },
          customDomain: true,
          favIcon: '/src/assets/ecopara/Bestpara.png',
        },
        10116: {
          name: 'Pararivage',
          logo: pararivage,
          logoSizes: {
            width: 200,
            height: 57,
          },

          palette: {
            primary: '#57beb1',
            primary_hover: calculateTint('#57beb1', -30),
            secondary: calculateTint('#57beb1', 90),
            secondary_hover: calculateTint('#57beb1', 70),
          },
          customDomain: true,
          favIcon: '/src/assets/ecopara/pararivage.png',
        },
        10068: {
          name: 'Natural para',
          logo: naturalpara,
          logoSizes: {
            width: 200,
            height: 142,
          },
          palette: {
            primary: '#83b9ac',
            primary_hover: calculateTint('#83b9ac', -30),
            secondary: calculateTint('#83b9ac', 90),
            secondary_hover: calculateTint('#83b9ac', 70),
          },
          customDomain: true,
          favIcon: '/src/assets/ecopara/naturalpara.png',
        },
        // Parasquare
        10067: {
          name: 'Parasquare',
          logo: parasquare,
          logoSizes: {
            width: 200,
            height: 25,
          },
          palette: {
            primary: '#F9BDBE',
            primary_hover: calculateTint('#F9BDBE', -30),
            secondary: calculateTint('#F9BDBE', 90),
            secondary_hover: calculateTint('#F9BDBE', 70),
          },

          customDomain: true,
          favIcon: '/src/assets/ecopara/parasquare.png',
        },
        10064: {
          name: 'Notre para mall',
          logo: notreparamall,
          logoSizes: {
            width: 200,
            height: 102,
          },
          palette: {
            primary: '#A6DA15',
            primary_hover: calculateTint('#A6DA15', -30),
            secondary: calculateTint('#A6DA15', 90),
            secondary_hover: calculateTint('#A6DA15', 70),
          },
          customDomain: true,
          favIcon: '/src/assets/ecopara/notreparamall.png',
        },
        10065: {
          name: 'Param2a',
          logo: param2a,
          logoSizes: {
            width: 200,
            height: 200,
          },
          palette: {
            primary: '#0CCBE4',
            primary_hover: calculateTint('#0CCBE4', -30),
            secondary: calculateTint('#0CCBE4', 90),
            secondary_hover: calculateTint('#0CCBE4', 70),
          },
          customDomain: true,
          favIcon: '/src/assets/ecopara/param2a.png',
        },
        10066: {
          name: 'Baltic beauty shop',
          logo: balticbeautyshop,
          logoSizes: {
            width: 200,
            height: 142,
          },

          palette: {
            primary: '#08A493',
            primary_hover: calculateTint('#08A493', -30),
            secondary: calculateTint('#08A493', 90),
            secondary_hover: calculateTint('#08A493', 70),
          },
          customDomain: true,
          favIcon: '/src/assets/ecopara/balticbeautyshop.png',
        },
        10072: {
          name: 'Salus',
          logo: salus,
          logoSizes: {
            width: 200,
            height: 66,
          },
          palette: {
            primary: '#5AB9A4',
            primary_hover: calculateTint('#5AB9A4', -30),
            secondary: calculateTint('#5AB9A4', 90),
            secondary_hover: calculateTint('#5AB9A4', 70),
          },
          customDomain: true,
          favIcon: '/src/assets/ecopara/salus.png',
        },
        10075: {
          name: 'Paradiem',
          logo: paradiem,
          logoSizes: {
            width: 200,
            height: 153,
          },
          palette: {
            primary: '#B79971',
            primary_hover: calculateTint('#B79971', -30),
            secondary: calculateTint('#B79971', 90),
            secondary_hover: calculateTint('#B79971', 70),
          },
          customDomain: true,
          favIcon: '/src/assets/ecopara/paradiem.png',
        },
        10077: {
          name: 'Alopara',
          logo: alopara,
          logoSizes: {
            width: 200,
            height: 200,
          },
          palette: {
            primary: '#D6E93E',
            primary_hover: calculateTint('#D6E93E', -30),
            secondary: calculateTint('#D6E93E', 90),
            secondary_hover: calculateTint('#D6E93E', 70),
          },
          customDomain: true,
          favIcon: '/src/assets/ecopara/alopara.png',
        },
        // Pharmatrex
        10142: {
          name: 'Pharmatrex',
          logo: pharmatrex,
          logoSizes: {
            width: 200,
            height: 80,
          },

          palette: {
            primary: '#56abdf',
            primary_hover: calculateTint('#56abdf', -30),
            secondary: calculateTint('#56abdf', 90),
            secondary_hover: calculateTint('#56abdf', 70),
          },
          customDomain: true,
          favIcon: '/src/assets/ecopara/pharmatrex.png',
        },
        10129: {
          name: 'Wellnesspara',
          logo: wellness,
          logoSizes: {
            width: 200,
          },
          palette: {
            primary: '#0369ab',
            primary_hover: calculateTint('#0369ab', -30),
            secondary: calculateTint('#0369ab', 90),
            secondary_hover: calculateTint('#0369ab', 70),
          },
          customDomain: true,
          favIcon: '/src/assets/ecopara/wellness.jpg',
        },
        10134: {
          name: 'Paraljazira',
          logo: paraljazira,
          logoSizes: {
            width: 200,
          },
          palette: {
            primary: '#104b5f',
            primary_hover: calculateTint('#104b5f', -30),
            secondary: calculateTint('#104b5f', 90),
            secondary_hover: calculateTint('#104b5f', 70),
          },
          customDomain: true,
          favIcon: '/src/assets/ecopara/paraljazira.jpg',
        },
        // https://santenature.ma/
        10159: {
          name: 'Santenature',
          logo: santenature,
          logoSizes: {
            width: 200,
          },
          palette: {
            primary: '#53c34b',
            primary_hover: calculateTint('#53c34b', -30),
            secondary: calculateTint('#53c34b', 90),
            secondary_hover: calculateTint('#53c34b', 70),
          },
          customDomain: true,
          favIcon: '/src/assets/ecopara/santenature.png',
        },
        10073: {
          name: 'Ecopara',
          logo: Ecopara_logo,
          palette: {
            primary: '#3F8C7D',
            primary_hover: '#317066',
            secondary: '#D8EFE8',
            secondary_hover: '#B2DDD1',
          },
          favIcon: 'ecopara_favicon.ico',
          notSignUp: true,
        },
        // parasconti
        10074: {
          name: 'Ecopara',
          logo: Ecopara_logo,
          palette: {
            primary: '#3F8C7D',
            primary_hover: '#317066',
            secondary: '#D8EFE8',
            secondary_hover: '#B2DDD1',
          },
          favIcon: 'ecopara_favicon.ico',
          notSignUp: true,
        },
        10014: {
          name: 'Ecopara',
          logo: Ecopara_logo,
          palette: {
            primary: '#3F8C7D',
            primary_hover: '#317066',
            secondary: '#D8EFE8',
            secondary_hover: '#B2DDD1',
          },
          favIcon: 'ecopara_favicon.ico',
          notSignUp: true,
        },
        //   Ecopara com global
        10055: {
          name: 'Ecopara',
          logo: Ecopara_logo,
          palette: {
            primary: '#3F8C7D',
            primary_hover: '#317066',
            secondary: '#D8EFE8',
            secondary_hover: '#B2DDD1',
          },
          favIcon: 'ecopara_favicon.ico',
          notSignUp: true,
        },
      };
