import { useAddUpdate } from './useAddUpdate';
import { useNavigate, useSearchParams } from 'react-router-dom';
import API from '@/services/API';
// import { removeParam } from '@/helpers/functions';
import { UseFormSetError } from 'react-hook-form';
import { useAppDispatch } from './reduxHooks';
import { setLoading, setUser } from '@/redux/features/tokenSlice';
import { User } from '@/types/global';
import { useCallback } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';
import { EXT } from '@/env';
import { removeParam } from '@/helpers/functions';
interface LoginParams {
  formData: { login?: string };
}
interface LoginPassWordParams {
  formData: { password: string };
  setError?: UseFormSetError<{ password: string }>;
}

const useAuth = () => {
  const { Add, formLoading } = useAddUpdate();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const redirectUri = params.get('redirectUri');
  const { updateServiceWorker } = useRegisterSW({
    onRegistered() {
      // console.info('onRegistered,r', r);
    },
  });
  const dispatch = useAppDispatch();
  async function login({ formData }: LoginParams) {
    Add<{ isRegistered: boolean }>({
      url: '/auth/identifier',
      formData,
      cb: (data) => {
        formData.login && localStorage.setItem('email', formData.login);
        formData.login && params.set('email', formData.login);
        console.log(data?.isRegistered, redirectUri);
        if (data?.isRegistered) {
          console.log(data?.isRegistered);
          if (redirectUri) {
            navigate(`/login/password?${params}&email=${formData.login}`);
          } else {
            navigate(`/login/password?email=${formData.login}`);
          }
        } else {
          navigate(`/signup?${params}`);
        }
      },
    });
  }

  const getUser = useCallback(async (signal?: AbortSignal) => {
    try {
      dispatch(setLoading(true));
      const res = await API.get('/account', { params: {}, signal });
      dispatch(setUser(res.data.data as User));
      res && updateServiceWorker(true);
    } catch (error) {
      dispatch(setLoading(true));
    }
  }, []);

  async function loginPassword({ formData, setError }: LoginPassWordParams) {
    Add({
      url: '/auth/sessions',
      formData,
      cb: async () => {
        await getUser();
        localStorage.removeItem('email');
        try {
          if (redirectUri) {
            const url = new URL(redirectUri);
            const hostname = url.hostname;
            if (hostname === `api.account.sobrus.${EXT}`) {
              let alteredURL = redirectUri;
              if (alteredURL.indexOf('?') !== -1) {
                alteredURL = removeParam('prompt', redirectUri);
              }
              window.location.href = alteredURL;
            } else {
              navigate('/home');
            }
          } else {
            navigate('/home');
          }
        } catch (error) {
          navigate('/home');
        }
      },
      cbError: (error) => {
        setError?.('password', {
          type: 'manual',
          message: error as string,
        });
      },
    });
  }
  const logout = async () => {
    try {
      await API.delete('/auth/sessions');
      localStorage.clear();
      window.location.href = '/';
    } catch (error) {
      // console.log(error);
    }
  };
  return { formLoading, login, loginPassword, logout, getUser };
};

export default useAuth;
