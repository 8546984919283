/* eslint-disable import/no-mutable-exports */
let BASE_URL = 'https://api.account.sobrus.ovh';
let EXT = 'ovh';

if (import.meta.env.VITE_REACT_APP_ENV === 'production') {
  BASE_URL = 'https://api.account.sobrus.com';
  EXT = 'com';
}

export { BASE_URL, EXT };
